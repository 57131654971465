import { Button } from 'commons/components'
import React from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'

import FormTambahkanRole from '../components/FormTambahkanRole'

const TambahRolePage = props => {
  return (
    <div>
      <Link to={`/settings/role`}>
        <Button className="absolute z-10 top-24 left-8" variant="secondary">
          Kembali
        </Button>
      </Link>

      <FormTambahkanRole {...props} />
    </div>
  )
}

export default TambahRolePage
