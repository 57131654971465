import { Button } from 'commons/components'
import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'

import getRoles from '../services/getRoles'

import FormTambahkanUser from '../components/FormTambahkanUser'

const TambahUserPage = props => {
  const [roles, setRoles] = useState()

  useEffect(() => {
    const fetch = async () => {
      const { data: rolesResponse } = await getRoles()

      setRoles(rolesResponse.data)
    }
    fetch()
  }, [])

  return roles ? (
    <div>
      <Link to={`/settings/user`}>
        <Button className="absolute z-10 top-24 left-8" variant="secondary">
          Kembali
        </Button>
      </Link>

      <FormTambahkanUser {...{ roles }} {...props} />
    </div>
  ) : (
    <></>
  )
}

export default TambahUserPage
